import React from 'react'
import { Link } from 'gatsby'
import { Box, Heading, Button } from 'theme-ui'
import Section from '@components/Section'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    mb: 2,
    span: {
      color: `omegaDark`
    }
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`,
    width: `5/6`
  }
}

export default () => (
  <>
    <Section>
      <Heading variant='h1' sx={styles.heading}>
        <span>Welcome!</span> I am Tom
      </Heading>
      <Heading variant='h4' sx={styles.running}>
        I'm someone who enjoys and studies mobile and web development like you. When I'm not working on a project, I'm fully involved in the community and would love to help more people achieve their professional goals.
        I'm also very curious about different cultures, so you'll also find my nose directed at other business or travel blogs.
      </Heading>
    </Section>
    <Box variant='buttons.group' sx={styles.buttons}>
      Please, enjoy the content!
    {/* <span>Please, if you find something wrong, email us.</span>
    We are here to help each others. */}
      {/* <Button as={Link} to='/'>
        Start Learning
      </Button>
      <Button variant='white' as={Link} to='/authors'>
        View Tutors
      </Button> */}
    </Box>
  </>
)
